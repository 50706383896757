<template>
  <div :class="$style.home">
    <div :class="$style.info">
      <div :class="$style.item">
        <div>
          用户可用积分
          <a-popover>
            <template slot="content"> 系统已经发放，可供用户使用且在有效期内的积分数量。 </template>
            <i class="ri-question-line" />
          </a-popover>
        </div>
        <div :class="$style.num">{{ thousands(info.amount) }}</div>
      </div>
      <div :class="$style.item">
        <div>
          用户冻结积分
          <a-popover>
            <template slot="content"> 根据积分冻结规则，尚未发放至用户账号的积分数量。 </template>
            <i class="ri-question-line" />
          </a-popover>
        </div>
        <div :class="$style.num">{{ thousands(data.frozen) }}</div>
      </div>
      <div :class="$style.item">
        <div>
          累计发放积分
          <a-popover>
            <template slot="content"> 系统累计发放所有的积分数量。 </template>
            <i class="ri-question-line" />
          </a-popover>
        </div>
        <div :class="$style.num">{{ thousands(data.total) }}</div>
      </div>
      <div :class="$style.item">
        <div>
          累计回收积分
          <a-popover>
            <template slot="content"> 实际被用户消耗的积分（累计发放积分数量-累计过期的积分数量） </template>
            <i class="ri-question-line" />
          </a-popover>
        </div>
        <div :class="$style.num">{{ thousands(data.recycle) }}</div>
      </div>
    </div>

    <div :class="$style.table">
      <div :class="$style.header">
        <span>近期明细</span>
        <BjButton class="btn-default" @click="onList()">
          <i class="left ri-menu-2-line" />
          全部记录
        </BjButton>
      </div>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="id">
        <template #user="item">
          <div :class="$style.user">
            <img :src="item.avatar" />
            <span>{{ item.nickname }}</span>
          </div>
        </template>
        <template #status="item">
          <BjTag :type="item.status === 0 ? 'default' : item.status === 1 ? 'primary' : 'danger'">
            {{ item.status | state }}
          </BjTag>
        </template>
      </a-table>
    </div>

    <bj-modal
      title="详情"
      :visible="visible.detail"
      :body-style="{ height: '400px' }"
      @ok="visible.detail = false"
      @cancel="visible.detail = false"
    >
      <div v-if="detail.id">
        <div :class="$style.box">
          <div :class="$style.left">交易编号</div>
          <div :class="$style.right">
            {{ detail.nickname }}
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">发起用户</div>
          <div :class="$style.right">
            <img :src="detail.avatar" />
            <span class="ml-10 primary">{{ detail.id }}</span>
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易类型</div>
          <div :class="$style.right">{{ detail.type ? '回收' : '发放' }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易场景</div>
          <div :class="$style.right">{{ detail.source_name }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易明细</div>
          <div :class="$style.right">{{ detail.detail }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">积分数量</div>
          <div :class="$style.right">{{ detail.amount }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易状态</div>
          <div :class="$style.right">{{ detail.status | state }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易时间</div>
          <div :class="$style.right">{{ detail.created_at }}</div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { integralManageService } from '@/service'
import { thousands } from '@/utils'

const service = new integralManageService()

export default {
  name: 'Home',
  filters: {
    state(val) {
      switch (val) {
        case 0:
          return '冻结中'
        case 1:
          return '成功'
        case 2:
          return '失败'
      }
    },
  },
  data() {
    return {
      info: {
        amount: 0, // 可用积分
        frozen: 0, // 冻结积分
        recycle: 300, //已回收积分
        expired: 0, //过期积分
        total: 0, // 累计发放
      },
      visible: {
        detail: false,
      },
      loading: false,
      data: [],
      detail: {
        id: null,
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '交易编号',
          dataIndex: 'id',
        },
        {
          title: '发起用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        {
          title: '交易类型',
          dataIndex: 'type',
          customRender: item => (item.type ? '回收' : '发放'),
        },
        {
          title: '交易场景',
          dataIndex: 'source_name',
        },
        {
          title: '积分数量',
          dataIndex: 'amount',
        },
        {
          title: '交易状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '交易时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onDetail(item)}>
              详情
            </bj-link>,
          ],
        },
      ]
    },
  },
  created() {
    this.getInfo()
    this.getList()
  },
  methods: {
    thousands,
    async getInfo() {
      try {
        const { data } = await service.getInfo()
        this.info = data
      } catch (e) {}
    },
    async getList() {
      try {
        const { data } = await service.getDetail({
          page: 1,
          page_size: 20,
        })
        this.data = data.record
      } catch (e) {}
    },
    onDetail(item) {
      this.visible.detail = true
      this.detail = item
    },
    onList() {
      this.$router.push({
        name: 'integralManageDetail',
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 50px 45px;
    color: #5c5c5c;
    font-size: 14px;
    background-color: #fff;

    .num {
      margin-top: 2px;
      color: #000;
      font-size: 26px;
    }

    i {
      color: #bbb;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .table {
    min-height: calc(100vh - 342px);
    padding: 20px;
    background-color: #fff;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;
    }

    .user {
      display: flex;
      align-items: center;
      color: @primary-color;

      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }
}

.box {
  display: flex;
  align-items: center;
  height: 32px;

  .left {
    width: 110px;
  }

  .right {
    flex: 1;

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}
</style>
